import logo from './logo.svg';
import './App.css';

import React from 'react';
import AddNotes from './AddNotes';
import NoteList from './NoteList';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div>
          <AddNotes />
          <NoteList />
        </div>
      </header>
    </div>
  );
}

export default App;